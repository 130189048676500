import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Autoplay, Keyboard, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useMediaQuery } from 'usehooks-ts';

import { IonImg } from '@ionic/react';

import 'swiper/css/pagination';

function HomeSectionHowItWorks() {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 992px)', {});

  const [carouselIndex, setCarouselIndex] = useState<1 | 2 | 3 | 4>(isMobile ? 1 : 2);

  const images = [
    '/images/home/home-how-it-works-1',
    '/images/home/home-how-it-works-2',
    '/images/home/home-how-it-works-3',
    '/images/home/home-how-it-works-4',
  ];

  return (
    <div className="application-width relative mx-auto flex max-w-full flex-col gap-6 py-6 pt-2 text-center lg:py-10 xl:max-w-[1300px]">
      <h3 className="text-32 font-7 lg:text-48">{t('home.howItWorks')}</h3>
      <h4 className="h-10 text-16 font-5 text-neutral-500 lg:text-28">
        {t(`home.howItWorks${carouselIndex}`)}
      </h4>

      <div className="relative mx-auto flex max-w-full flex-col gap-6 lg:max-w-[95%]">
        <div>
          <Swiper
            autoplay={{ delay: 6000 }}
            centeredSlides
            initialSlide={isMobile ? 0 : 1}
            keyboard
            loop={!isMobile}
            modules={[Keyboard, Pagination, Autoplay]}
            onSlideChangeTransitionEnd={(swiper) => setCarouselIndex((swiper.realIndex + 1) as 1)}
            onSlideChangeTransitionStart={(swiper) => setCarouselIndex((swiper.realIndex + 1) as 1)}
            pagination={{
              bulletActiveClass:
                '!h-[17.5px] !w-[17.5px] lg:!h-6 lg:!w-6 border-[1.5px] lg:border-[2.5px] border-[#F9FAFB] shadow-[0_0_0_0.5px_rgba(0,0,0,0.3)] lg:shadow-[0_0_0_0.75px_rgba(0,0,0,0.3)] !shadow-gobazar',
              bulletClass:
                'h-3 w-3 lg:h-4 lg:w-4 bg-gobazar-400 inline-block rounded-full cursor-pointer',
              clickable: true,
              el: '.swiper-custom-pagination',
            }}
            slidesPerView={isMobile ? 1 : 3}
            spaceBetween={isMobile ? 20 : 60}
          >
            {images.map((image, index) => (
              <SwiperSlide
                className="duration-700 [&.swiper-slide-active]:scale-105 lg:[&.swiper-slide-active]:scale-115"
                key={`slide-${index}`}
              >
                <IonImg
                  alt={`Slide ${index + 1}`}
                  className="mx-auto aspect-[7/10] !min-w-[300px] max-w-[90%] object-contain"
                  src={`${image}-${language == 'kk' ? 'kz' : 'ru'}.webp`}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="swiper-custom-pagination flex flex-row items-center justify-center gap-5" />
      </div>
    </div>
  );
}

export default memo(HomeSectionHowItWorks);
